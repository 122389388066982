<template>
  <div class="header">
    <a v-if="!header.backLink" @click="$router.back(-1)">
      <div class="back" v-if="header.back"></div>
    </a>

    <a v-else @click="$router.push({name: header.backLink})">
      <div class="back" ></div>
    </a>

    <span class="title">{{ header.title }}
    </span>
    <div class="link" v-if="!header.removeClose"  @click="$router.push({name:'home'});"></div>
  </div>
</template>

<script setup>
import { useHeaderStore } from '@/stores/header'
import {useRoute} from "vue-router";
const store = useHeaderStore()
const { header } = store;
const route = useRoute()

</script>

<style scoped>

</style>