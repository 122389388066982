<template>
  <Header ></Header>
  <router-view/>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import {useAppStore} from "@/stores/app";
import {mapState} from "pinia";

const preloader = document.querySelector('.preloader');
export default {
  name: 'HomeView',
  components: {
    Header
  },

  data() {
    return {
    }
  },
  computed: {
    ...mapState(useAppStore, ['loading']),
  },
  watch: {
    loading: {
      handler(val) {
        preloader.style.display = val ? '' : 'none';
      },
      immediate: true,
    },
  }
}
</script>

<style lang="less">
@import '@/assets/less/common.less';
</style>
