<template>
  <div v-if="!disabled" class="info-bar">
    <a >
      <span>{{ text }}</span>
      <span class="close"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "info-bar",
  props: ['disabled','text'],
}
</script>
