export default {
    methods:{
        appEvent(eventName, data){
            try {
                ostinApp.postMessage(JSON.stringify({
                    event: eventName,
                    data: data
                }))
            } catch (e){

            }

        }
    },
}