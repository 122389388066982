<template>

  <router-link
      :to="{name: link, params: attr}"
      v-slot="{href, route, navigate}"
      :class="{'disabled' : disabled}"
      @click.native="scrollToTop(); $emit('event')"
      :event="!disabled ? 'click' : ''"
      class="primary-button"
  >
    <button :href="href" @click="navigate">
      {{ text }}
    </button>
  </router-link>


</template>

<script>
export default {
  name: "primary-button",
  props: ['text', 'link', 'disabled', 'attr'],
}
</script>
