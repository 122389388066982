import { useAuthStore } from '@/stores/auth';
// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxODE0ODI0MzA1LCJpYXQiOjE3Mjg0MjQzMDUsImp0aSI6IjdiNzg4ZTU2MmJlMzRkYTM5MTY2OGY4YjAyZWIxN2Q2IiwiY2xpZW50X2hhc2giOiI3ZWQ2MGJjYjMyYjNmYzZiZTVkOGE3NjcyNjZiY2Q4OWNjODM2YTZkIn0.3njICopTOPJzaM_Dti193zUJh4-RPXVVBEAw_IvM73E"
const baseUrl = 'https://ostin.thecapsula.ru/api' // import.meta.env.VITE_API_URL
let user = JSON.parse(localStorage.getItem('user')) || {token:null}
let token = null
if (user.token){
    token = user.token
} else {
    token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxODE0ODI0MzA1LCJpYXQiOjE3Mjg0MjQzMDUsImp0aSI6IjdiNzg4ZTU2MmJlMzRkYTM5MTY2OGY4YjAyZWIxN2Q2IiwiY2xpZW50X2hhc2giOiI3ZWQ2MGJjYjMyYjNmYzZiZTVkOGE3NjcyNjZiY2Q4OWNjODM2YTZkIn0.3njICopTOPJzaM_Dti193zUJh4-RPXVVBEAw_IvM73E"
}



export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};



function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(baseUrl + url)
        };
        requestOptions.headers['Authorization'] = "Bearer " + token;
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(baseUrl + url, requestOptions).then(handleResponse);
    }
}

function authHeader(url) {
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.token;
    const isApiUrl = url.startsWith(baseUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${token}` }
        // return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const {user, errorPage} = useAuthStore();
                if ([400,401,402,403,404].includes(response.status) && user) {
                    errorPage();
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        }
        catch (e) {
            console.log('Ошибка парсинга',e)
        }
    });
}