<template>
  <ul class="images-col">
    <li v-for="item in data">
      <div
          class="face"
          :class="[
                    { 'active' : this.multiSet.has(item.value)},
                    { 'unselected' : this.multiSet.size > 0 && !this.multiSet.has(item.value)} ]"
          @click="multiSelect(item.value,item.name.text)"
      >
        <div>
          <span class="title" v-html="item.name.text"></span>
          <span class="description">{{ item.description.text }}</span>
        </div>
      </div>
    </li>
  </ul>
  <div>
  </div>
</template>

<script>
import {useAnketaStore} from "@/stores/anketa";
import anketaMixin from "@/mixins/anketaMixin";
import {ref} from "vue";
import {mapState} from "pinia";

export default {
  name: 'FaceBlock',
  mixins: [anketaMixin],
  props: ['data'],
  setup() {

    const {setValue, getStepData, steps} = useAnketaStore();
    let multiSet = ref(new Set(getStepData(steps.step5) || []));
    return {
      setValue, getStepData, steps, multiSet
    };
  },
  methods: {
    multiSelect(val) {
      this.$emit('isSelected')
      if (this.multiSet.has(val))
        this.multiSet.delete(val);
      else {
        if (this.multiSet.size <= 1)
          this.multiSet.add(val);
      }
      if (this.multiSet.size <= 0) {
        this.$emit('resetSelection')
      }
      this.setValue(this.steps.step5, [...this.multiSet])
    },
  },
  computed: {
    ...mapState(useAnketaStore, ['anketa'])
  },
  data() {
    return {
      step_number: "step5",
    }
  },

}
</script>

