import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers/auth';
import router from "@/router";
import common from "@/composables/common";
import {useUserStore} from "@/stores/users";

// const baseUrl = `${import.meta.env.VITE_API_URL}/users`;


export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')) ?? {token: null, hash:null, name: null},
        returnUrl: null
    }),
    actions: {
        async login(username, password) {

            const userStore = useUserStore()
                const api = await fetchWrapper.post('/token/', { name:username, client_hash: password });
                if (api.access) {
                    userStore.setUser(username,password,api.access)
                } else {
                    console.log('api auth error')
                }
           return true
        },
        errorPage(){
            router.push('/error/505');
        }
    }
});