<template>
  <div class="anketa-2cols">
    <span class="title">
      Выбери свой цвет кожи. Подсказка, как понять свой подтон
      <a @click='showModal()'>здесь</a>
    </span>
    <div>
      <SkinBlock :data="datas"
                 @isSelected="isSelected()"
      />
    </div>
    <primary-button
        @click="saveStep(button.link)"
        :disabled="disabled"
        :text="button.text"
        :link="button.link"/>
  </div>
  <anketa-modal ref="modal"
                :content="modal.content"
                :btn-text="modal.btnText"/>
</template>

<script>
import SkinBlock from "@/components/Anketa/SkinBlock";
import skinData from "@/data/anketa/skinData";
import {useHeaderStore} from '@/stores/header'
import AnketaModal from "@/components/UI/AnketaModal";
import headerMixin from "@/mixins/headerMixin";
import {useSettingsStore} from "@/stores/settings";
import selectedMixin from "@/mixins/selectedMixin";

export default {
  name: 'SkinQuestionView',
  components: {AnketaModal, SkinBlock},
  mixins: [headerMixin, selectedMixin],
  props: ['header'],
  setup() {
    const {setHeaderData} = useHeaderStore();
    const settings = useSettingsStore();
    return {setHeaderData, settings};
  },
  methods: {
    showModal: function () {
      this.$refs.modal.show = true
    }
  },
  data() {
    return {
      stepId: 'skin_tone',
      button: {
        text: 'Продолжить',
        link: 'anketa.step4',
      },
      modal: {
        content: "<div class='title'>Как определять подтон</div>" +
            "<p>Посмотри на цвет вен: если они фиолетово-голубые, то твой подтон холодный, а если зелёные - теплый.</p>" +
            "<div class='title'>Как определить цвет кожи</div>" +
            "<p>Ориентируйся на то, как на тебя ложится загар и какой цвет тонального крема ты выбираешь. Чем скорее ты обгораешь на солнце и чем светлее твой тональный крем, тем более светлый у тебя цвет кожи. И наоборот.</p>",
        btnText: "Поняла",
      }
    }
  }
}
</script>
