<template>
    <ul class="images-2-cols skins">
      <li v-for="item in data">
            <div
                class="skin"
                :class="[
                    {'active' : active_item === item.value},
                    {'unselected' : active_item && active_item !== item.value}
                  ]"
                :style="{ background: item.color }"
                @click="select(item.value, item.name.text)"
            >
              <div>
                <em v-html="item.name.text"></em>
                <span :style="{color: item.description.text }">{{  item.description.text  }}</span>
              </div>
            </div>
      </li>
    </ul>
  <div >
  </div>
</template>

<script>
import {useAnketaStore} from "@/stores/anketa";
import anketaMixin from "@/mixins/anketaMixin";

export default {
  name: 'SkinBlock',
  mixins: [anketaMixin],
  props: ['data'],
  setup() {
    const { setValue,getStepData,steps } = useAnketaStore();
    return {
      setValue,getStepData,steps
    };
  },
  data() {
    return {
      step_number: "step3",
    }
  },

}
</script>

