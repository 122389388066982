import {defineStore} from 'pinia'
import {fetchWrapper} from '@/helpers/auth';
import common from "@/composables/common";
import router from "@/router";
import {useAppStore} from "@/stores/app";

export const useSettingsStore = defineStore('settings', {
    state: () => (
        {
            settings: JSON.parse(localStorage.getItem("settings")) || null,
            returnUrl: null
        }
    ),
    actions: {
        async fetchApiSettings() {
            const app = useAppStore();

            if (this.settings === null) {
                try {
                    const api = await fetchWrapper.get('/settings/')
                        .catch(function (){
                            return router.push({name: 'error.505'})
                    })
                        .finally(() => app.setLoading(false));

                    common.saveToLocalStorage("settings", api.form)
                    this.settings = JSON.parse(localStorage.getItem("settings"))
                } catch (e) {
                    return router.push({name: 'error.505'})
                }
            } else {
                app.setLoading(false);
                return this.settings
            }
        },

        async postUserAnketa(anketa) {
            try {
                const api = await fetchWrapper.post('/form/', anketa);
            } catch (e) {
                return router.push({name: 'error.505'})
            }
        },
        async getUserAnketa() {
            try {
                const api = await fetchWrapper.get('/form/');
            } catch (e) {
                return router.push({name: 'error.505'})
            }
        },

        parseSettings(val){
            let result = false
            var i = this.settings.findIndex(function (e) {
                return e.name == val;
            });
            i != -1
                ? result = this.settings[i].data.choices
                : result;
            return result;
        },

        getSetting(val) {
            try {
                if (!this.settings)
                    return this.fetchApiSettings().then(() => this.parseSettings(val))
                else
                    return this.parseSettings(val)
            } catch (e) {

            }

        }
    }

})
