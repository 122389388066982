<template>
  <div class="anketa-2cols styles">
    <div v-html="title" class="title"></div>
    <div class="bottom">
      <VueScrollPicker
          v-if="options"
          :options="options"
          v-model="modelValue"
          empty="Error"
          placeholder="-"
      />
    </div>
    <primary-button
        @click="finished()"
        :disabled="disabled"
        :text="button.text"
        :link="button.link"/>
  </div>

</template>

<script>
import StyleBlock from "@/components/Anketa/StyleBlock";
import {useHeaderStore} from '@/stores/header'
import anketaMixin from "@/mixins/anketaMixin";
import {useAnketaStore} from "@/stores/anketa";
import {ref} from "vue";
import bottomData from "@/data/anketa/bottomData";
import headerMixin from "@/mixins/headerMixin";
import selectedMixin from "@/mixins/selectedMixin";
import {useSettingsStore} from "@/stores/settings";
import appMixin from "@/mixins/appMixin";

export default {
  name: 'TopQuestionView',
  components: {StyleBlock},
  mixins: [anketaMixin,headerMixin, selectedMixin, appMixin],
  props: ['header'],
  setup() {
    const { setValue,getStepData,steps,postAnketa } = useAnketaStore();
    let modelValue = ref(getStepData(steps.step9))
    const {setHeaderData} = useHeaderStore();
    const settings = useSettingsStore();
    return {
      setHeaderData, setValue,getStepData,steps,
      modelValue, settings, postAnketa
    };
  },
  watch:{
    modelValue(newVal){
      this.select(newVal)
      this.disabled = newVal == null;
    }
  },
  created() {
    this.datas = this.settings.getSetting(this.stepId)
    Object.keys(this.datas).forEach(key => {
      this.options.push({
        value: this.datas[key].value,
        name: this.datas[key].name.text,
      });
    });
  },
  mounted() {
    if (this.modelValue == null) {
      this.disabled = true
    }
  },
  methods:{
    finished(){
      this.appEvent('onAnketaIsFinished',{})
      this.setValue('finished',true)
      this.postAnketa()
    }
  },
  data() {
    return {
      stepId: 'bottom_size',
      title: "<span>Выбери какой у тебя размер низа:</span>",
      button: {
        text: 'Завершить',
        link: 'home',
      },
      step_number: "step9",
      options: [],
    }
  }
}
</script>
<style scoped>


</style>
