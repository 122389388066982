import { defineStore } from 'pinia';

export const useAppStore = defineStore({
    id: 'app',
    state: () => ({
        loading: true,
    }),
    actions: {
        setLoading(loading) {
            this.loading = loading;
        },
    }
});