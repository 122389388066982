<template>
  <div class="anketa-2cols faces">
    <div v-html="title"></div>
    <div>
      <FaceBlock :data="datas"
                 @isSelected="isSelected()"
                 @resetSelection="resetSelected()"
      />
    </div>

    <primary-button
        @click="saveStep(button.link)"
        :disabled="disabled"
        :text="button.text"
        :link="button.link"
    />
  </div>
</template>

<script>
import FaceBlock from "@/components/Anketa/FaceBlock";
import {useHeaderStore} from '@/stores/header'
import headerMixin from "@/mixins/headerMixin";
import {useSettingsStore} from "@/stores/settings";
import selectedMixin from "@/mixins/selectedMixin";
import {mapState} from "pinia";
import {useAnketaStore} from "@/stores/anketa";
import {ref} from "vue";

export default {
  name: 'FaceQuestionView',
  components: {FaceBlock},
  mixins: [headerMixin, selectedMixin],
  props: ['header'],
  setup() {
    const {setHeaderData} = useHeaderStore();
    const { getStepData,steps } = useAnketaStore();
    const settings = useSettingsStore();
    let multiSet = ref(new Set(getStepData(steps.step5) || []));
    return {setHeaderData, settings,multiSet};
  },
  computed: {
    ...mapState(useAnketaStore, ['anketa'])
  },
  mounted() {
    this.datas = this.settings.getSetting(this.stepId)
    this.getInitialValue()
  },
  methods:{
    getInitialValue() {
      try {
        this.disabled = this.multiSet.size <= 0;
      } catch (e) {
        console.log(e.error)
      }
    },
  },
  data() {
    return {
      stepId: 'face_feature',
      title: "<span class='title'>Какие у тебя черты лица?</span>" +
          "<span class='description'>Выбери не более 2-х</span>",
      button: {
        text: 'Продолжить',
        link: 'anketa.step6',
      },
    }
  }
}
</script>
