import {createRouter, createWebHashHistory} from 'vue-router'
import EyeQuestionView from "@/views/Anketa/EyeQuestionView";
import SkinQuestionView from "@/views/Anketa/SkinQuestionView";
import FigureQuestionView from "@/views/Anketa/FigureQuestionView";
import FaceQuestionView from "@/views/Anketa/FaceQuestionView";
import StyleQuestionView from "@/views/Anketa/StyleQuestionView";
import HeightQuestionView from "@/views/Anketa/HeightQuestionView";
import TopQuestionView from "@/views/Anketa/TopQuestionView";
import BottomQuestionView from "@/views/Anketa/BottomQuestionView";
import CompletionQuestionView from "@/views/Anketa/CompletionQuestionView";
import {useAnketaStore} from "@/stores/anketa";
import {useApiStore} from "@/stores/api";
import {useSettingsStore} from "@/stores/settings";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        props: {
            header: {
                back: false,
                title: 'Мои образы',
                link: "/"
            },
            bar: {
                text: "НОВЫЕ ОБРАЗЫ ПОЯВЯТСЯ УЖЕ В НОЯБРЕ!",
                disabled: false
            }
        }
    },
    {
        path: '/init/:hash/:name',
        name: 'init',
        component: () => import(/* webpackChunkName: "home" */ '../views/InitView.vue'),
        props: {
            // name: this.query.name,
            header: {
                back: false,
                title: 'Мои образы',
                link: "/"
            },
            bar: {
                text: "НОВЫЕ ОБРАЗЫ ПОЯВЯТСЯ УЖЕ В НОЯБРЕ!",
                disabled: false
            }
        }
    },
    {
        path: '/personal',
        name: 'personal',
        component: () => import(/* webpackChunkName: "personal" */ '../views/PersonalCardView.vue'),
        props: {
            header: {
                back: true,
                title: 'Личная карта стиля',
                link: "/"
            },
        }
    },
    {
        path: '/styles',
        name: 'styles',
        component: () => import(/* webpackChunkName: "styles" */ '../views/StylesView.vue'),
        props: {
            header: {
                back: true,
                title: 'Образы от стилистов',
                link: "/"
            },
        }
    },
    {
        path: '/other',
        name: 'other',
        component: () => import(/* webpackChunkName: "other" */ '../views/AllStylesView.vue'),
        props: {
            header: {
                back: true,
                title: 'Все образы',
                link: "/"
            },
        }
    },
    {
        path: '/compilation/:id?/:value?',
        name: 'compilation',
        component: () => import(/* webpackChunkName: "compilation" */ '../views/CompilationView.vue'),
        props: {
            header: {
                back: true,
                title: 'Подборка',
                link: 'home'
            },
        }
    },
    {
        path: '/compilation/others/:id?/:value?',
        name: 'otherCompilation',
        component: () => import(/* webpackChunkName: "compilation" */ '../views/OtherCompilationView.vue'),
        props: {
            header: {
                back: true,
                title: 'Подборка',
                link: 'home'
            },
        }
    },
    {
        name: "policy",
        path: '/policy',
        component: () => import(/* webpackChunkName: "error" */ '../views/PolicyView'),
        props: {
            header: {
                back: true,
                title: 'Обработка персональных данных',
                link: "/",
                remove: true
            }
        }
    },
    {
        path: '/anketa',
        name: 'anketa',
        redirect: '/anketa/fillout',
        props: {
            header: {
                back: false,
                title: 'Мои образы',
                link: 'home'
            }
        },
        component: () => import(/* webpackChunkName: "anketa" */ '../views/Anketa/AnketaView.vue'),
        children: [
            {
                name: "anketa.fillout",
                path: '/anketa/fillout',
                component: () => import(/* webpackChunkName: "fillout" */ '../views/Anketa/FillOutView.vue'),
                props: {
                    header: {
                        back: false,
                        title: 'Мои образы',
                        link: 'home'
                    }
                }
            },
            {
                name: "anketa.step1",
                path: '/anketa/step1',
                component: () => import(/* webpackChunkName: "step1" */ '../views/Anketa/HairQuestionView.vue'),
                props: {
                    header: {
                        back: true,
                        title: '1 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step2",
                path: '/anketa/step2',
                component: EyeQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '2 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step3",
                path: '/anketa/step3',
                component: SkinQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '3 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step4",
                path: '/anketa/step4',
                component: FigureQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '4 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step5",
                path: '/anketa/step5',
                component: FaceQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '5 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step6",
                path: '/anketa/step6',
                component: StyleQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '6 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step7",
                path: '/anketa/step7',
                component: HeightQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '7 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step8",
                path: '/anketa/step8',
                component: TopQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '8 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.step9",
                path: '/anketa/step9',
                component: BottomQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '9 из 9',
                        link: "/"
                    }
                }
            },
            {
                name: "anketa.completion",
                path: '/anketa/completion',
                component: CompletionQuestionView,
                props: {
                    header: {
                        back: true,
                        backLink: 'home',
                        title: 'Мои образы',
                        link: 'home'
                    }
                }
            },
        ],
    },
    {
        path: '/error',
        name: 'error',
        props: {
            header: {
                back: true,
                title: 'Мои образы',
                link: "/"
            },
        },
        component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
        children: [
            {
                name: "error.505",
                path: '/error/505',
                component: () => import(/* webpackChunkName: "error" */ '../components/Error/Error505.vue'),
                props: {
                    header: {
                        back: false,
                        title: 'Мои образы',
                        link: "/"
                    }
                }
            },
            {
                name: "error.event",
                path: '/error/event',
                component: () => import(/* webpackChunkName: "error" */ '../components/Error/ErrorEvent.vue'),
                props: {
                    header: {
                        back: true,
                        title: 'Мои образы',
                        link: "/"
                    }
                }
            },
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


router.beforeEach(async (to) => {

    const publicPages = [
        '/anketa',
        '/anketa/fillout',
        '/anketa/step1',
        '/anketa/step2',
        '/anketa/step3',
        '/anketa/step4',
        '/anketa/step5',
        '/anketa/step6',
        '/anketa/step7',
        '/anketa/step8',
        '/anketa/step9',
        '/anketa/completion',
        '/error/505',
        '/init',
        '/policy',
    ];

    const initPage = "/init"
    const errorPage = "/error/505"
    const path = to.path

    const anketaStore = useAnketaStore();
    const settings = useSettingsStore();
    const api = useApiStore();
    settings.fetchApiSettings()

    if (path.includes(initPage)){
        const lastStep = anketaStore.getStepData('last')
        console.log('Страница ининициализации')
        console.log('Анкета' + lastStep)

    }

    if (path.includes(errorPage)){
        console.log('Страница ошибки')
        // await api.getStyleCard().finally(() => this.setLoading(false));

    }
    if (!path.includes(initPage)){

            // const lastStep = anketaStore.getStepData('last')
            // // Если есть последний шаг
            // if (lastStep){
            //     return router.push({name:lastStep})
            // } else {
            //     return '/anketa/fillout';
            // }
    }
});

export default router
