let common = {
    addOrReplace(data, obj) {
        var i = data.findIndex(function (e) {
            return e.step == obj.step;
        });
        i != -1 ? data[i] = obj : data.push(obj);
        return data;
    },

    saveToLocalStorage(key, obj) {
        localStorage.setItem(key, JSON.stringify(obj));
    },

    getFromLocalStorage(key) {
        JSON.parse(localStorage.getItem(key))
    },

    array_splice(array, key) {
        let result = [];
        for (let i = 0; i < array.length; i++) {
            if (i != key) {
                result.push(array[i]);
            }
        }

        return result;
    }

}

export default common