<template>
  <div v-if="show" class="modal" @click.self="closeModal">
    <div class="modal-window" >
      <slot name="title" v-if="title">
        <h3 class="modal-title">{{ title }}</h3>
      </slot>
      <slot name="body">
        <div class="modal-content">
          <span  v-html="content"></span>
        </div>
      </slot>
      <slot name="footer">
        <div class="modal-footer">
          <primary-button
              :link="link"
              :text="btnText"
              @click="closeModal">
          </primary-button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/UI/PrimaryButton";
export default {
  name: "anketa-modal",
  components: {PrimaryButton},
  props: ['link','btnText','disabled','show', "title",'content'],
  methods: {
    closeModal: function () {
      this.show = false
    }
  },
  data(){
    return{
      link: false,
      show: this.show
    }
  }
}
</script>
