<template>
    <ul class="images-2-cols">
      <li v-for="item in data">
        <div
            class="style"
            :class="[
                    { 'active' : this.multiSet.has(item.value)},
                    { 'unselected' : this.multiSet.size > 0 && !this.multiSet.has(item.value)} ]"
            @click="multiSelect(item.value)"
            :style="{ backgroundImage: 'url(' + item.image + ')' }"
        >
            <span class="title" v-html="item.name.text"></span>
        </div>
      </li>
    </ul>
  <div >
  </div>
</template>

<script>
import {useAnketaStore} from "@/stores/anketa";
import anketaMixin from "@/mixins/anketaMixin";
import {ref} from "vue";

export default {
  name: 'StyleBlock',
  mixins: [anketaMixin],
  props: ['data'],
  setup() {

    const { setValue,getStepData,steps } = useAnketaStore();
    let multiSet = ref(new Set(getStepData(steps.step6) || []));
    return {
      setValue,getStepData,steps,multiSet
    };
  },
  methods:{
    multiSelect(val){
      if (this.multiSet.has(val))
        this.multiSet.delete(val);
      else {
        if (this.multiSet.size <= 1)
          this.multiSet.add(val);
      }
      this.setValue(this.steps.step6,[...this.multiSet])
      console.log(this.multiSet)
    },
  },

  data() {
    return {
      step_number: "step6",
    }
  },

}
</script>

