<template>
    <ul class="images-2-cols">
      <li v-for="item in data">
            <div
                class="eye"
                :class="[
                    {'active' : active_item === item.value},
                    {'unselected' : active_item && active_item !== item.value}
                  ]"
                :style="{ backgroundImage: 'url(' + item.image + ')' }"
                @click="select(item.value, item.name.text)"
            >
              <span :style="{color: item.name.color }">{{ item.name.text }}</span>
            </div>
      </li>
    </ul>
  <div >
  </div>
</template>

<script>
import {useAnketaStore} from "@/stores/anketa";
import anketaMixin from "@/mixins/anketaMixin";

export default {
  name: 'AnketaMain',
  mixins: [anketaMixin],
  props: ['data'],
  setup() {
    const { setValue,getStepData,steps } = useAnketaStore();
    return {
      setValue,getStepData,steps
    };
  },
  data() {
    return {
      step_number: "step2",
    }
  },

}
</script>

