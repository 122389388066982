import {mapState} from "pinia";
import {useAnketaStore} from "@/stores/anketa";

export default {
    mounted() {
        this.datas = this.settings.getSetting(this.stepId)
        this.getInitialValue(this.stepId)
    },
    computed: {
        ...mapState(useAnketaStore, ['anketa'])
    },

    methods: {
        saveStep(routeName){
            let store = useAnketaStore()
            store.saveLastStep(routeName)
        },

        isSelected() {
            this.disabled = false
        },
        resetSelected() {
            this.disabled = true
        },
        getInitialValue(step) {
            try {
                if (this.anketa) {
                    var i = this.anketa.findIndex(function (e) {
                        return e.step == step;
                    });
                    if (i != -1) {
                        this.disabled = false
                    }
                }
            } catch (e) {
                console.log(e.error)
            }
        },
    },
    data() {
        return {
            disabled: true,
            datas: false,
        }
    }
}