import axios from 'axios'
import VueAxios from 'vue-axios'
import { createApp } from 'vue'
import { createPinia } from "pinia"
import App from './App.vue'
import router from './router'
import components from '@/components/UI'
import VueScrollPicker from "vue-scroll-picker";

const pinia = createPinia()
const app = createApp(App)

components.forEach(component => {
    app.component(component.name,component)
})

app.config.globalProperties.scrollToTop = () => window.scrollTo(0,0)

app.use(router)
app.use(pinia)
app.use(VueAxios, axios)
app.use(VueScrollPicker);

app.mount('#app')



