import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers/auth';
import router from "@/router";
import common from "@/composables/common";

export const useApiStore = defineStore({
    id: 'api',
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')) ?? {token: null, hash:null, name: null},
        returnUrl: null
    }),
    actions: {
        async getStyleCard() {
                const api = await fetchWrapper.get('/stylecard/').catch(function (e){
                    console.log(e)
                });
                if (api) {
                    common.saveToLocalStorage("stylecard",api)
                    return api
                } else {
                    console.log('Error: api stylecard')
                    return {}
                }
        },
        async fetchImages() {
                const api = await fetchWrapper.get('/images/').catch(function (e){
                    console.log(e)
                });
                if (api) {
                    common.saveToLocalStorage("images",api)
                    return api
                } else {
                    console.log('Error: fetch api images')
                    return {}
                }
        },

        async onObrazEvent(event,value) {
                const data = {
                    "onObrazLiked" : event,
                    "data": {
                        "obrazId": value
                    }
                }
                const api = await fetchWrapper.post('/events/',data).catch(function (e){
                    console.log(e)
                });
                if (api) {
                    common.saveToLocalStorage("collection",api)
                    return api
                } else {
                    console.log('Error: fetch api collection')
                    return {}
                }
        },

        async getPreview() {
                const api = await fetchWrapper.get('/preview/').catch(function (e){
                    console.log(e)
                });
                if (api) {
                    common.saveToLocalStorage("preview",api)
                    return api
                } else {
                    console.log('Error: fetch api collection')
                    return {}
                }
        },

        async fetchCollection() {
                const api = await fetchWrapper.get('/collection/').catch(function (e){
                    console.log(e)
                });
                if (api) {
                    common.saveToLocalStorage("collection",api)
                    return api
                } else {
                    console.log('Error: fetch api collection')
                    return {}
                }
        },
        errorPage(){
            router.push('/error/505');
        }
    }
});