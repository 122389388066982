<template>
    <ul class="images-col">
      <li v-for="item in data">
        <div
            class="figure"
            :class="[
                    {'active' : active_item === item.value},
                    {'unselected' : active_item && active_item !== item.value}
                  ]"
            :style="{ backgroundImage: 'url(' + item.image + ')' }"
            @click="select(item.value,item.name.text)"
        >
              <div>
                <span class="title" v-html="item.name.text"></span>
                <span class="description">{{  item.description.text  }}</span>
              </div>
            </div>
      </li>
    </ul>
  <div >
  </div>
</template>

<script>
import {useAnketaStore} from "@/stores/anketa";
import anketaMixin from "@/mixins/anketaMixin";

export default {
  name: 'FigureBlock',
  mixins: [anketaMixin],
  props: ['data'],
  setup() {
    const { setValue,getStepData,steps } = useAnketaStore();
    return {
      setValue,getStepData,steps
    };
  },
  data() {
    return {
      step_number: "step4",
    }
  },

}
</script>

